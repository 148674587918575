var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{staticClass:"q-pa-md"},[_c('loading-alert',{attrs:{"display":_vm.displayLoading}}),_c('brewthers-alert',{attrs:{"display":_vm.displayAlert,"title":_vm.alertTitle,"message":_vm.alertMessage,"type":_vm.alertType,"redirect":_vm.redirect},on:{"accept":function($event){_vm.displayAlert = false}}}),_c('div',[_c('div',{staticClass:"text-h5 q-mb-md text-white"},[_vm._v("Redactar blog")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-xs-12 q-pa-md"},[_c('q-input',{attrs:{"filled":"","dark":"","type":"text","label":"Titulo del blog","rules":[function (val) { return !!val || 'El campo es obligatorio'; }]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-xs-12 q-pa-md"},[_c('q-file',{attrs:{"bottom-slots":"","filled":"","dark":"","label":"Blog banner","rules":[function (val) { return !!val || 'El campo es obligatorio'; }]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"fas fa-paperclip"})]},proxy:true},{key:"hint",fn:function(){return [_vm._v("Tamaño recomendado: 1920 x 400 (px)")]},proxy:true}]),model:{value:(_vm.bannerImage),callback:function ($$v) {_vm.bannerImage=$$v},expression:"bannerImage"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col q-pa-md"},[_c('q-editor',{attrs:{"dark":"","toolbar-bg":"grey-9","dense":_vm.$q.screen.lt.md,"toolbar":[
                        [
                            {
                                label: _vm.$q.lang.editor.align,
                                icon: _vm.$q.iconSet.editor.align,
                                fixedLabel: true,
                                list: 'only-icons',
                                options: [
                                    'left',
                                    'center',
                                    'right',
                                    'justify' ],
                            } ],
                        ['hr', 'link'],
                        ['fullscreen'],
                        [
                            {
                                label: _vm.$q.lang.editor.formatting,
                                icon: _vm.$q.iconSet.editor.formatting,
                                list: 'no-icons',
                                options: [
                                    'p',
                                    'h1',
                                    'h2',
                                    'h3',
                                    'h4',
                                    'h5',
                                    'h6',
                                    'code' ],
                            },
                            {
                                label: _vm.$q.lang.editor.fontSize,
                                icon: _vm.$q.iconSet.editor.fontSize,
                                fixedLabel: true,
                                fixedIcon: true,
                                list: 'no-icons',
                                options: [
                                    'size-1',
                                    'size-2',
                                    'size-3',
                                    'size-4',
                                    'size-5',
                                    'size-6',
                                    'size-7' ],
                            },
                            'removeFormat' ],
                        [
                            'quote',
                            'unordered',
                            'ordered',
                            'outdent',
                            'indent' ],

                        ['undo', 'redo'],
                        ['viewsource'] ]},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})],1)]),_c('div',{staticClass:"row text-right"},[_c('q-space'),_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-3 col-xs-12 q-pa-md"},[_c('q-btn',{staticClass:"full-width",attrs:{"color":"warning","label":!_vm.isEditing
                            ? 'Guardar borrador'
                            : 'Actualizar borrador'},on:{"click":function($event){return _vm.saveAsDraft()}}})],1),_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-3 col-xs-12 q-pa-md"},[_c('q-btn',{staticClass:"full-width",attrs:{"color":"secondary","label":!_vm.isEditing ? 'Guardar y Publicar' : 'Actualizar'},on:{"click":function($event){return _vm.saveAsPublic()}}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }