var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{staticClass:"pattern-bg q-pa-xl",staticStyle:{"height":"auto","background-repeat":"repeat-y"}},[_c('generic-navbar'),_c('loading-alert',{attrs:{"display":_vm.displayLoading}}),_c('brewthers-alert',{attrs:{"display":_vm.displayAlert,"title":_vm.alertTitle,"message":_vm.alertMessage,"type":_vm.alertType,"redirect":_vm.redirect},on:{"accept":function($event){_vm.displayAlert = false}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col desktop-only"}),_c('div',{staticClass:"col-lg-4"},[_c('q-card',{staticClass:"full-width q-mt-xl",attrs:{"dark":""}},[_c('q-form',{on:{"submit":function($event){return _vm.createBrewery()}}},[_c('q-card-section',{staticClass:"text-center"},[_c('q-img',{staticStyle:{"width":"150px"},attrs:{"src":require('@/assets/brewthers-logo.png')}})],1),_c('q-card-section',[_c('div',{staticClass:"text-h6 text-center"},[_vm._v(" ¿Quieres vender tus productos con nosotros? "),_c('br'),_vm._v(" Llena el siguiente formulario para solicitar tu cuenta. ")])]),_c('q-card-section',[_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"Nombre de casa cervecera","dark":"","rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.breweryInfo.brewingHouseName),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "brewingHouseName", $$v)},expression:"breweryInfo.brewingHouseName"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"RUC","dark":"","rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.breweryInfo.ruc),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "ruc", $$v)},expression:"breweryInfo.ruc"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"Nombre","dark":"","rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.breweryInfo.name),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "name", $$v)},expression:"breweryInfo.name"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"Apellido","dark":"","rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.breweryInfo.lastName),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "lastName", $$v)},expression:"breweryInfo.lastName"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"Correo","dark":"","rules":[
                                function (val) { return val.length > 0 ||
                                    'El campo es obligatorio'; },
                                function (val) { return _vm.validEmail.test(val) ||
                                    'Formato de correo incorrecto'; } ]},model:{value:(_vm.breweryInfo.email),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "email", $$v)},expression:"breweryInfo.email"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"Celular","dark":"","mask":"####-####","fill-mask":"","rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.breweryInfo.phone),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "phone", $$v)},expression:"breweryInfo.phone"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"Contraseña","dark":"","type":"password","rules":[
                                function (val) { return val.length > 0 ||
                                    'El campo es obligatorio'; },
                                function (val) { return _vm.strongPass.test(val) ||
                                    'Debe tener 8 caracteres e incluir mayuscula, minuscula y numero.'; } ]},model:{value:(_vm.breweryInfo.password),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "password", $$v)},expression:"breweryInfo.password"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"primary","label":"Repetir contraseña","dark":"","type":"password","rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.breweryInfo.repassword),callback:function ($$v) {_vm.$set(_vm.breweryInfo, "repassword", $$v)},expression:"breweryInfo.repassword"}})],1),_c('q-card-actions',[_c('q-space'),_c('q-btn',{attrs:{"label":"Registrar","color":"primary","type":"submit"}})],1)],1)],1)],1),_c('div',{staticClass:"col desktop-only"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }