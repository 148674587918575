<template>
    <q-layout view="lHh Lpr lFf" class="brewthers-dark-bg">
        <generic-navbar />
        <q-page-container>
            <q-page>
                <div class="row">
                    <q-space />
                    <div class="col-lg-6 q-px-lg terms-copy">
                        <div class="text-h4 text-center q-my-xl">
                            POLÍTICAS DE PRIVACIDAD
                        </div>
                        <div class="text-justify">
                            <!-- <div class="text-body2 q-mb-sm q-mt-lg">
                                Esta política de privacidad es válida para el
                                sitio web
                                <a href="http://www.brewthersco.com"
                                    >www.brewthersco.com</a
                                >
                            </div> -->
                            <div class="text-body2 q-mt-md">1. GENERAL</div>
                            <div class="text-body2">
                                Estamos comprometidos con la protección de la
                                privacidad de todas aquellas personas que
                                visiten la página web
                                <a href="http://www.brewthersco.com"
                                    >www.brewthersco.com</a
                                >
                                ("BREWTHERS"). Por favor, lea las siguientes
                                políticas de privacidad donde se explica cómo
                                utilizamos y protegemos su información. Al
                                visitar y/o inscribirse y/o solicitar servicios
                                en esta Página Web, usted acepta y donde se le
                                requiera, consiente que los datos facilitados
                                sean recabados, utilizados y transmitidos
                                conforme a lo establecido en las presentes
                                políticas de privacidad.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                1.1 Cuando visite BREWTHERS para realizar un
                                pedido se le podrá solicitar que nos proporcione
                                información sobre usted, incluidos su nombre,
                                datos de contacto e información sobre una
                                tarjeta de crédito o de débito. También podremos
                                recabar información sobre su uso de la Página
                                Web y de nuestros Servicios, de los mensajes que
                                deje en la Página Web y de los correos
                                electrónicos, cartas o cualquier metodo de
                                comunicacion que nos remita.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                1.2 Al acceder a la información de BREWTHERS y/o
                                a la Página web utilizando las rutas digitales
                                de los móviles como por ejemplo (pero no
                                limitados a estos ejemplos) móvil, tableta u
                                otro dispositivo / tecnología, incluyendo las
                                aplicaciones móviles, la recogida y utilización
                                de sus datos se ajustará a lo establecido en la
                                presente Política de Privacidad. Podemos recabar
                                información técnica de su dispositivo móvil, del
                                Sitio Web o del Servicio que se realiza a través
                                de un dispositivo móvil, como por ejemplo, los
                                datos de localización y ciertas características
                                de rendimiento y datos sobre, el dispositivo,
                                soporte / sistema operativo, incluyendo el
                                dispositivo y el tipo de conexión, dirección IP,
                                métodos de pago móvil, la interacción con otras
                                tecnologías de retail como el uso de NFC Tags,
                                los códigos QR o el uso de cupones móviles. A
                                menos que haya optado por permanecer en el
                                anonimato a través de su dispositivo y / o
                                configuración de la plataforma, esta información
                                puede ser recogida y utilizada por nosotros de
                                forma automática si utiliza el Sitio Web o el
                                Servicio a través de su dispositivo móvil (s) o
                                cualquiera tecnología que accese la página.
                            </div>
                            <div class="text-body2 q-mt-md">
                                2. USO DE DATOS
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                2.1 La información que nos facilite nos
                                permitirá proporcionarle acceso a determinadas
                                partes de la Página Web y prestarle los
                                servicios que nos haya solicitado. También nos
                                permitirá facturar y contactarle nosotros o el
                                medio de entrega, cuando resulte necesario con
                                relación a su pedido. Por ejemplo, nosotros
                                podemos usar su información para ofrecerle
                                actualizaciones del estado de su pedido y otra
                                información acerca de su pedido por e-mail,
                                teléfono, móvil o mensajería móvil (por ejemplo,
                                SMS, MMS, etc.) Asimismo, utilizaremos y
                                analizaremos la información recabada, de forma
                                que podamos administrar, apoyar, mejorar y
                                desarrollar nuestro negocio así como con fines
                                estadísticos y de análisis y para ayudar a
                                prevenir el fraude. En su caso, ahora y en el
                                futuro podrá manifestar sus preferencias con
                                relación al uso de sus datos según lo
                                establecido en esta Política de Privacidad, y
                                podrá ser ejercido según el método que usted
                                haya elegido para utilizar el Servicio, por
                                ejemplo, aplicaciones de teléfonos móviles o a
                                través de la Página Web.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                2.2 Si usted no desea que utilicemos sus datos
                                para estos fines o cambiara de opinión sobre ser
                                contactado en el futuro, por favor, póngase en
                                contacto con nosotros a través del correo
                                electrónico
                                <a href="mailto:brewthers3@gmail.com"
                                    >brewthers3@gmail.com</a
                                >
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                2.3 Por favor, tenga en cuenta que al realizar
                                comentarios y opiniones sobre la Página Web y
                                sobre los servicios, usted consiente en que
                                incluyamos dichos comentarios y opiniones en la
                                Página Web y en cualesquiera materiales de
                                marketing o publicidad. A estos efectos sólo le
                                identificamos por su nombre de pila y por su
                                ciudad de residencia.
                            </div>
                            <div class="text-body2 q-mt-md">
                                3. DIVULGACIÓN DE DATOS
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                3.1 La información que usted nos proporcione
                                será transferida y se conservará en nuestros
                                servidores y podrán acceder a la misma nuestro
                                personal.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                3.2 Podremos utilizar la información que usted
                                nos proporcione si tuviéramos el deber de
                                revelar o compartir su información para cumplir
                                con (y/o creamos tener la obligación de tener
                                que cumplir con) cualquier obligación legal o
                                con el fin de aplicar las Condiciones de nuestra
                                Página Web y de cualquier otro contrato suscrito
                                con usted, o para proteger los derechos de
                                BREWTHERS. Con ellos nos referimos a cruzar
                                información con otras empresas y organizaciones
                                con el objeto de evitar y proteger el fraude.
                            </div>
                            <div class="text-body2 q-mt-md">
                                4. SEGURIDAD Y RETENCIÓN DE DATOS
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                4.1 Adoptaremos las medidas de protección de
                                datos necesarias para evitar accesos no
                                autorizados y tratamientos ilegales, pérdidas
                                accidentales, destrucción y daños. Conservaremos
                                su información por el plazo que resulte
                                legalmente exigible.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                4.2 En el supuesto de que hubiera escogido una
                                contraseña que le permita acceder a determinadas
                                partes de la Página Web, será responsable de
                                mantener la confidencialidad de dicha
                                contraseña. Le recomendamos no compartir su
                                contraseña con nadie.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                4.3 Desafortunadamente, la transmisión de
                                información a través de internet no es
                                completamente segura. A pesar de que adoptaremos
                                las medidas para proteger sus datos, no podemos
                                garantizar la seguridad de los datos que
                                transmita a la Página Web toda transmisión se
                                realizará a su propio riesgo. Una vez recibida
                                la información, utilizaremos procedimientos
                                estrictos y medidas de seguridad para trata de
                                evitar los accesos no autorizados.
                            </div>
                            <div class="text-body2 q-mt-md">
                                5. DERECHO DE ACCESOS Y AUTORIZACIÓN
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                5.1 Sus datos personales, serán tratados y
                                quedarán incorporados en los archivos de
                                BREWTHERS con el fin de dar una efectiva
                                respuesta o prestación de los servicios
                                solicitados, facilitar la tramitación de los
                                pedidos, la realización de estudios estadísticos
                                y el envío de publicidad de las promociones
                                ofertadas por BREWTHERS a través de su Sitio
                                Web. En este sentido, el usuario consiente de
                                forma expresa, el tratamiento de sus datos
                                personales remitidos a BREWTHERS para las
                                finalidades indicadas en este documento.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                5.2 Usted tendrá derecho de acceso a sus datos
                                personales para verificar la fidelidad e
                                integridad de los mismos, así como a los efectos
                                de consultar el uso que de los mismos se está
                                dando y los nombres de las personas a los que se
                                haya facilitado.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                5.3 También tiene derecho a solicitar la
                                corrección o eliminación de datos personales o a
                                objetar cualquier tratamiento de los mismos.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                5.4 Para obtener información y ejercitar sus
                                derechos de acceso, rectificación, cancelación y
                                oposición al tratamiento de los mismos, por
                                favor contacte con nosotros utilizando los datos
                                de contacto que figuran en la sección
                                “CONTÁCTANOS”.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                5.5 En todo caso, BREWTHERS conservará de forma
                                confidencial y cumpliendo la obligación de
                                secreto de todos los datos personales recabados
                                y contenidos en sus archivos según lo dispuesto
                                en la normativa vigente en materia de protección
                                de datos y de carácter personal.
                            </div>
                            <div class="text-body2 q-mt-md">
                                6. CAMBIOS DE NUESTRA POLÍTICA DE PRIVACIDAD
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md">
                                6.1 Cualesquiera cambios a nuestras Políticas de
                                Privacidad serán incluidos en la Página Web y,
                                cuando corresponda, a través de notificación por
                                correo electrónico.
                            </div>
                            <div class="text-body2 q-mt-md q-pl-md q-mb-xl">
                                6.2 Todos los comentarios, consultas y
                                solicitudes relativas a nuestra utilización de
                                la información sobre usted serán bienvenidos y
                                deberán remitirse a
                                <a href="mailto:brewthers3@gmail.com"
                                    >brewthers3@gmail.com</a
                                >
                            </div>
                        </div>
                    </div>
                    <q-space />
                </div>
            </q-page>
        </q-page-container>
        <brewthers-footer />
    </q-layout>
</template>

<script>
import GenericNavbar from '@/components/general/GenericNavbar'
import Footer from '@/components/general/Footer'

export default {
    components: {
        'generic-navbar': GenericNavbar,
        'brewthers-footer': Footer,
    },
    mounted() {
        window.scrollTo(0, 0)
    },
}
</script>

<style>
.terms-copy a {
    color: #24a2c2 !important;
}
</style>
