var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-table',{attrs:{"title":"Articulos en inventario","data":_vm.data,"columns":_vm.columns,"row-key":"name","binary-state-sort":"","dark":"","pagination":_vm.initialPagination},on:{"update:pagination":function($event){_vm.initialPagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-td',{key:"name",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.name)+" "),_c('q-popup-edit',{attrs:{"title":"Actualizar nombre","buttons":"","dark":""},on:{"save":function($event){return _vm.validateInput({
                            id: props.row.id,
                            newName: props.row.name,
                        })}},model:{value:(props.row.name),callback:function ($$v) {_vm.$set(props.row, "name", $$v)},expression:"props.row.name"}},[_c('q-input',{attrs:{"type":"text","dense":"","autofocus":"","dark":"","rules":[function (val) { return !!val || 'El campo es obligatorio'; }]},model:{value:(props.row.name),callback:function ($$v) {_vm.$set(props.row, "name", $$v)},expression:"props.row.name"}})],1)],1),_c('q-td',{key:"type",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.type)+" ")]),_c('q-td',{key:"inventory",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.inventory)+" "),_c('q-popup-edit',{attrs:{"title":"Actualizar cantidad","buttons":"","dark":""},on:{"save":function($event){return _vm.validateInventoryChange({
                            id: props.row.id,
                            inventory: props.row.inventory,
                        })}},model:{value:(props.row.inventory),callback:function ($$v) {_vm.$set(props.row, "inventory", $$v)},expression:"props.row.inventory"}},[_c('q-input',{attrs:{"type":"number","dense":"","autofocus":"","dark":"","rules":[function (val) { return !!val || 'El campo es obligatorio'; }]},model:{value:(props.row.inventory),callback:function ($$v) {_vm.$set(props.row, "inventory", $$v)},expression:"props.row.inventory"}})],1)],1),(_vm.brewerys)?_c('q-td',{key:"brewery",attrs:{"props":props}},[_vm._v(_vm._s(_vm.returnBreweryName(props.row.brewery)))]):_vm._e(),_c('q-td',{key:"status",attrs:{"props":props}},[_c('q-btn',{attrs:{"color":props.row.status == 'inactive'
                            ? 'secondary'
                            : 'warning',"size":"xs","label":props.row.status == 'inactive'
                            ? 'Activar'
                            : 'Desactivar'},on:{"click":function($event){return _vm.$emit('changestatus', {
                            id: props.row.id,
                            status: props.row.status,
                        })}}})],1),_c('q-td',[_c('q-btn',{attrs:{"color":"info","size":"xs","label":"Detalles","to":("/item-details/" + (props.row.id))}})],1),_c('q-td',[_c('q-btn',{attrs:{"color":"red-7","size":"xs","label":"Eliminar"},on:{"click":function($event){return _vm.$emit('delete', {id: props.row.id})}}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }