var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{staticClass:"pattern-bg"},[_c('div',{staticClass:"absolute-bottom"}),_c('div',{staticClass:"absolute-center"},[_c('div',{staticClass:"row"},[_c('q-img',{staticClass:"q-mb-lg",attrs:{"src":require('@/assets/logo-horizontal.png')}}),_c('q-card',{staticClass:"q-pa-lg shadow-1",attrs:{"dark":"","square":""}},[_c('q-card-section',[_c('q-form',{staticClass:"q-gutter-md"},[_c('q-input',{attrs:{"dark":"","square":"","filled":"","type":"password","label":"Nueva contraseña","rules":[
                                function (val) { return val.length > 0 ||
                                    'El campo es obligatorio'; },
                                function (val) { return _vm.strongPass.test(val) ||
                                    'Debe tener 8 caracteres e incluir mayuscula, minuscula y numero.'; } ]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('q-input',{attrs:{"dark":"","square":"","filled":"","type":"password","label":"Repite contraseña","rules":[
                                function (val) { return val.length > 0 ||
                                    'El campo es obligatorio'; },
                                function (val) { return val == _vm.form.password ||
                                    'Las contraseñas no coinciden'; } ]},model:{value:(_vm.repassword),callback:function ($$v) {_vm.repassword=$$v},expression:"repassword"}})],1)],1),_c('q-card-actions',{staticClass:"q-px-md"},[_c('q-btn',{staticClass:"full-width",attrs:{"unelevated":"","color":"primary","size":"lg","label":"Cambiar Contraseña"},on:{"click":_vm.updatePassword}})],1),(_vm.dismissCountDown > 0)?_c('q-card-section',[_c('q-banner',{staticClass:"bg-red text-white",attrs:{"inline-actions":"","rounded":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.dismissCountDown = 0}}},[_c('i',{staticClass:"fas fa-times"})])]},proxy:true}],null,false,2209311503)},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }