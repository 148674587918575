import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=7f6288c9&"
import script from "./Drawer.vue?vue&type=script&lang=js&"
export * from "./Drawer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDrawer,QScrollArea,QList,QItem,QAvatar,QItemSection});qInstall(component, 'directives', {Ripple});
