var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{staticClass:"q-pa-lg"},[_c('loading-alert',{attrs:{"display":_vm.displayLoading}}),_c('brewthers-alert',{attrs:{"display":_vm.displayAlert,"title":_vm.alertTitle,"message":_vm.alertMessage,"type":_vm.alertType},on:{"accept":function($event){_vm.displayAlert = false}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col desktop-only"}),_c('div',{staticClass:"col-lg-9"},[_c('div',[_c('div',{staticClass:"row q-px-md q-my-lg"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"text-h4"},[_vm._v("Historial de pedidos")])])]),_c('div',{staticClass:"row q-px-md"},[(Object.keys(_vm.data).length !== 0)?_c('div',{staticClass:"col-12"},_vm._l((_vm.data),function(order,index){return _c('q-card',{key:index,staticClass:"full-width q-mb-lg",attrs:{"dark":""}},[_c('q-card-section',[_c('div',{staticClass:"text-h5"},[_c('span',{staticStyle:{"color":"#27a3c3"}},[_vm._v("No. de pedido:")]),_vm._v(" "+_vm._s(order.id)+" - "),_c('span',{staticStyle:{"color":"#27a3c3"}},[_vm._v("Estado:")]),_vm._v(" "+_vm._s(_vm.returnStatus(order.status))+" ")]),_c('div',{staticClass:"text-subtitle-2"},[_c('span',{staticStyle:{"color":"#27a3c3"}},[_vm._v("Fecha:")]),_vm._v(" "+_vm._s(_vm.returnTime(order.logs[0]))+" ")])]),_c('q-separator',{attrs:{"dark":""}}),_c('q-card-section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 col-sm-12 col-xs-12"},_vm._l((order.cart),function(item,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-lg-7 col-sm-6 col-xs-12"},[_c('order-item-details',{attrs:{"data":_vm.removeUnUsedItem(
                                                            item
                                                        )}})],1),_c('div',{staticClass:"col-lg-2 col-sm-2 col-xs-6 q-mb-md"},[_c('div',{staticClass:"text-h6",staticStyle:{"color":"#27a3c3"}},[_vm._v(" Cantidad ")]),_c('div',{staticClass:"text-subtitle-3"},[_vm._v(" "+_vm._s(item.amount)+" ")])]),_c('div',{staticClass:"col-lg-2 col-sm-4 col-xs-6 q-mb-md"},[_c('div',{staticClass:"text-h6",staticStyle:{"color":"#27a3c3"}},[_vm._v(" Precio unitario ")]),_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" $ "+_vm._s(item.price)+" ")])])])}),0),(
                                            order.paymentMethod ===
                                                'nequi' ||
                                            order.paymentMethod ===
                                                'yappy' ||
                                            order.paymentMethod === 'ach'
                                        )?_c('div',{staticClass:"col-lg-3 col-xs-12"},[_c('order-proof-of-payment',{attrs:{"data":order.paymentProof,"fullOrder":order,"orderId":order.firebaseId,"restaurantId":order.restaurantId,"disableprop":order.status === 'cancel' ||
                                                order.status === 'completed'}})],1):_vm._e()])]),_c('q-separator',{attrs:{"dark":""}}),_c('q-card-actions',{staticClass:"q-pa-md"},[_c('q-space'),_c('div',{staticClass:"text-h6 on-left"},[_c('span',{staticStyle:{"color":"#27a3c3"}},[_vm._v("Total:")]),_vm._v(" $ "+_vm._s(parseInt(order.total).toFixed(2))+" ")]),(order.status === 'review')?_c('q-btn',{staticClass:"on-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.cancelOrder(order)}}},[_vm._v("Cancelar")]):_vm._e()],1)],1)}),1):_c('div',{staticClass:"col-12 q-mt-xl"},[_c('q-card',{staticClass:"full-width text-center",attrs:{"dark":""}},[_c('q-card-section',[_c('div',{staticClass:"text-h1 q-mb-md"},[_c('i',{staticClass:"fas fa-boxes text-grey-8"})]),_c('div',{staticClass:"text-h5 text-grey-8"},[_vm._v(" Aun no has realizado pedidos. ")])])],1)],1)])])]),_c('div',{staticClass:"col desktop-only"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }