<template>
    <div class="movingbeer-bg flex text-white" style="position: relative">
        <div class="absolute-center">
            <div class="text-h2 title-section desktop-only q-mb-md">
                movingbeer
            </div>
            <div class="text-h3 title-section mobile-only q-mb-md">
                movingbeer
            </div>
            <div
                class="text-h6 q-mb-lg"
                style="font-family: GilroyExtraBold; text-align: center;"
            >
                Espacio para conectar y facilitar el comercio entre las Casas
                Cerveceras y tu negocio.
            </div>
            <div class="row justify-center">
                <a
                    class="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle bg-primary text-white q-btn--actionable q-focusable q-hoverable q-btn--wrap"
                    href="/movingbeer"
                    target="blank"
                >
                    <span class="q-focus-helper" tabindex="-1"></span>
                    <span class="q-btn__wrapper col row q-anchor--skip">
                        <span
                            class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
                            >acceder</span
                        >
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style></style>
