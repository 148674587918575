<template>
    <q-layout view="lHh Lpr lFf" class="brewthers-dark-bg">
        <generic-navbar />
        <q-page-container>
            <q-page>
                <div class="row">
                    <div class="col"></div>
                    <div class="col-6 terms-copy">
                        <div class="text-h4 text-center q-mt-xl">
                            TÉRMINOS Y CONDICIONES
                        </div>
                        <div class="text-justify">
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                1- Términos y Condiciones de Uso y Compra del
                                Sitio “BREWTHERS”.
                            </div>
                            <div class="text-body2">
                                El presente documento contiene los términos y
                                condiciones que regulan el uso de
                                <a href="http://www.brewthersco.com"
                                    >www.brewthersco.com</a
                                >
                                (“Sitio”) así como del contrato (en adelante los
                                “Términos y Condiciones”) que vincula a el/los
                                usuario/s (en adelante “Usted”/”su” /
                                “Comprador/”) con BREWTHERS (en adelante
                                “nosotros” / “nuestro” / “el Vendedor”) en
                                relación con los productos y/o servicios que
                                ofrecemos a través de nuestro sitio web
                                <a href="http://www.brewthersco.com"
                                    >www.brewthersco.com</a
                                >.
                            </div>
                            <div class="text-body2">
                                Exhortamos al usuario a leer, entender y aceptar
                                todas las condiciones establecidas en los
                                Términos y Condiciones y en las Políticas de
                                Privacidad así como en los demás documentos
                                incorporados a los mismos por referencia.
                            </div>
                            <div class="text-body2">
                                Lea atentamente los presentes Términos y
                                Condiciones previo a su incorporación como
                                usuario en BREWTHERS y antes de realizar el
                                pedido. Al utilizar este sitio web o hacer un
                                pedido a través del mismo, Usted está consciente
                                de quedar vinculado por estos Términos y
                                Condiciones, por lo que, si no está de acuerdo
                                con todas las condiciones, no debe realizar
                                ningún pedido.
                            </div>
                            <div class="text-body2">
                                De igual manera hacemos de su conocimiento que
                                los “Términos y Condiciones” podrían ser
                                modificados sin previo aviso. En caso de tener
                                alguna pregunta relacionada con los Términos y
                                Condiciones de compra usted podrá contactarnos a
                                través de nuestra pestaña de “Contáctanos”.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                2- USO DEL SITIO WEB (
                                <a href="http://www.brewthersco.com"
                                    >www.brewthersco.com)</a
                                >
                            </div>
                            <div class="text-body2">
                                Estas condiciones son importantes tanto para
                                Usted como para nosotros puesto que han sido
                                diseñadas para crear un acuerdo legalmente
                                vinculante entre nosotros, protegiendo sus
                                derechos como cliente y nuestros derechos como
                                empresa. Usted declara que, al hacer su pedido,
                                ha leído y acepta sin reserva alguna los
                                presentes Términos y Condiciones. Si Usted no
                                acepta estos Términos y Condiciones, los cuales
                                tienen un carácter obligatorio y vinculante,
                                deberá abstenerse a utilizar el Sitio y/o los
                                servicios y de realizar cualquier compra.
                            </div>
                            <div class="text-body2">
                                Al utilizar el sitio Usted acepta que:
                            </div>
                            <ul>
                                <li>
                                    *.-Sólo podrá hacer uso del sitio web para
                                    realizar consultas o pedidos legalmente
                                    válidos.
                                </li>
                                <li>
                                    *.-Se obliga a facilitarnos de manera cierta
                                    y correcta su dirección de correo
                                    electrónico, dirección de envío, número
                                    telefónico, así como otros datos de
                                    contacto, y consciente que podremos hacer
                                    uso de esta información para despachar su
                                    pedido, a través de una empresa de envíos o
                                    para ponernos en contacto con usted si es
                                    necesario. Si no nos facilita toda la
                                    información requerida, no podremos tramitar
                                    su pedido. Los servicios sólo están
                                    disponibles para personas que tengan
                                    capacidad legal para contratar. Al realizar
                                    un pedido en este sitio web usted, nos
                                    garantiza que es mayor de 18 años y que
                                    tiene capacidad legal para celebrar
                                    contratos vinculantes.
                                </li>
                            </ul>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                3- DISPONIBILIDAD DEL SERVICIO
                            </div>
                            <div class="text-body2">
                                Los artículos que se ofrecen a través de este
                                sitio web estarán disponibles solamente para ser
                                enviados en La Ciudad de Panamá.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                4- REALIZACIÓN DE PEDIDOS
                            </div>
                            <div class="text-body2">
                                Para realizar un pedido, deberá seguir todos los
                                pasos de compra online, y hacer clic en el botón
                                “Completar compra”; posteriormente, recibirá un
                                correo electrónico con acuse de recibo de su
                                pedido (“Orden de compra”). Al momento de
                                efectuar el pago de la compra, le enviaremos un
                                correo en el que le informaremos acerca de todos
                                los detalles del envío de la mercancía comprada.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                5- DESCRIPCIÓN DE LOS PRODUCTOS
                            </div>
                            <div class="text-body2">
                                La información que se encuentra en el sitio web,
                                así como las fotos de los productos, son
                                verídicas y se deben tomar como la mejor
                                aproximación al producto real. El cliente debe
                                verificar toda la información concerniente al
                                producto antes de tomar su decisión de compra.
                                Las decisiones de compra de los clientes son
                                finales. BREWTHERS no aceptará reclamo por mala
                                interpretación de la información presentada.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                6- POLÍTICA DE DEVOLUCIÓN Y CANCELACIÓN
                            </div>
                            <div class="text-body2">
                                El usuario tiene derecho a reclamar el mal
                                estado o defectuosidad del artículo recibido, la
                                recepción de un producto que no coincida con lo
                                especificado en la descripción del producto
                                comprado. El reclamo deberá realizarse en un
                                plazo máximo de un (1) día hábil desde la
                                recepción del artículo en la dirección de
                                entrega indicada por el usuario, a través del
                                formulario de contacto o de nuestro correo
                                electrónico
                                <a href="mailto:brewthers3@gmail.com"
                                    >brewthers3@gmail.com</a
                                >
                            </div>
                            <div class="text-body2">
                                Deberá incluir también todos los documentos
                                (factura de la compra) y envoltorios originales
                                de los productos. No se hará ningún cambio si el
                                producto ha sido usado o si ha sufrido algún
                                daño, por mal uso o por una mala manipulación
                                del mismo, por lo que deberá ser cuidadoso con
                                el/los producto/s mientras estén en su posesión.
                            </div>
                            <div class="text-body2">
                                Una vez recibido su reclamo, se le indicará una
                                dirección a la cual debe enviar el producto
                                defectuoso o un punto de contacto para coordinar
                                la entrega del mismo. Seguido a la llegada del
                                producto al destino, es decir, a las oficinas de
                                BREWTHERS, procederemos a examinarlo y le
                                comunicaremos por vía correo electrónico, dentro
                                de un plazo razonable y acordado con nuestro
                                proveedor, si procede la sustitución del mismo.
                                La sustitución del artículo se efectuará lo
                                antes. No se aceptará devoluciones por
                                reembolso. Es decir, BREWTHERS enviará de nuevo
                                los productos que fueron ordenados y llegaron en
                                mal estado por los mismos en buen estado.
                            </div>
                            <div class="text-body2">
                                Una vez realizada una orden de compra, ésta
                                solamente podrá ser cancelada al momento de ser
                                contactado por BREWTHERS y solicite la
                                cancelación, de lo contrario la misma será
                                procesada para su despacho sin derecho a
                                cancelación.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                7- ENTREGA DEL PRODUCTO
                            </div>
                            <div class="text-body2">
                                Salvo que se produzcan circunstancias
                                extraordinarias, enviaremos el pedido del/ de
                                los producto/s, en un plazo no mayor a cuarenta
                                y ocho (48) horas. Las horas de entrega de
                                BREWTHERS serán de las 9:00am de la mañana a
                                6:00pm de la tarde.
                            </div>
                            <div class="text-body2">
                                Si por algún motivo no pudiésemos cumplir con la
                                fecha de entrega, le informaremos de este
                                inconveniente y le daremos la opción de seguir
                                adelante con la compra estableciendo una nueva
                                fecha de entrega o bien anular el pedido con el
                                reembolso total del precio pagado.
                            </div>
                            <div class="text-body2">
                                A efectos de las presentes condiciones, se
                                entenderá que se ha producido la “entrega” o que
                                el/los producto/s han sido “entregado/s” en el
                                momento de firmar la recepción de los mismos en
                                la dirección de entrega convenida.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                8- RIESGOS EXTRAORDINARIOS
                            </div>
                            <div class="text-body2">
                                BREWTHERS no será responsable por retraso o no
                                cumplimiento de alguna de las obligaciones que
                                asumamos al amparo del contrato, cuya causa se
                                deba a acontecimientos de “Fuerza Mayor”.
                            </div>
                            <div class="text-body2">
                                Las causas de fuerza mayor incluirán cualquier
                                acto, acontecimiento, falta de ejercicio,
                                omisión o accidente que esté fuera de nuestro
                                control razonable e incluirán en especial (sin
                                limitación), lo siguiente:
                            </div>
                            <ul>
                                <li>
                                    *-Huelgas, cierres patronales u otras
                                    medidas reivindicativas.
                                </li>
                                <li>
                                    *-Conmoción civil, revuelta, invasión,
                                    ataque terrorista o amenaza terrorista,
                                    guerra (ya haya sido declarada o no) o
                                    amenaza o preparativos de guerra.
                                </li>
                                <li>
                                    *-Incendio, explosión, tormenta, inundación,
                                    terremoto, hundimiento, epidemia o cualquier
                                    otro desastre natural.
                                </li>
                                <li>
                                    *-Imposibilidad de utilizar sistemas
                                    públicos o privados de telecomunicaciones.
                                </li>
                                <li>
                                    *-Actos, decretos, legislación, normativa o
                                    restricciones de otros gobiernos.
                                </li>
                                <li>
                                    *-Huelga, fallos o accidentes de transporte
                                    marítimo o fluvial, postal o cualquier otro
                                    tipo de transporte.
                                </li>
                            </ul>
                            <div class="text-body2">
                                En cualquiera de las circunstancias antes
                                mencionadas, se entenderá que nuestra obligación
                                de cumplimiento quedará suspendida durante el
                                período en que la causa de fuerza mayor
                                continúe, y dispondremos de una ampliación en el
                                plazo de ejecución para cumplir con la
                                obligación mientras dure tal período.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                9- TRANSMISIÓN DEL RIESGO
                            </div>
                            <div class="text-body2">
                                Los riesgos de los productos serán a su cargo a
                                partir del momento de la entrega.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                10- PRECIO
                            </div>
                            <div class="text-body2">
                                El precio de los productos será el que se
                                estipule en nuestro sitio web. Los precios del
                                sitio incluyen sólo ITBMS para Panamá. En cuanto
                                a las bebidas alcohólicas, estas llevan un ITBMS
                                de 10%.
                            </div>
                            <div class="text-body2">
                                Los precios pueden cambiar en cualquier momento,
                                pero (salvo en lo establecido anteriormente) los
                                posibles cambios no afectarán a los pedidos que
                                ya se encuentre en estado de “En preparación”.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                11- PROCESO DE PAGO
                            </div>
                            <div class="text-body2">
                                Una vez que haya seleccionado sus artículos a
                                través del botón “Añadir“, estos se habrán
                                añadido a su cesta de compra. Usted podrá
                                visualizarla en cualquier momento y modificar
                                las cantidades seleccionadas, haciendo clic en
                                el ícono de la cesta, en la parte superior
                                derecha del sitio web. Una vez seguro/a de su
                                orden, el paso siguiente será tramitar el
                                pedido:
                            </div>
                            <div class="text-body2">Para hacerlo debes:</div>
                            <ol>
                                <li>Hacer clic en “Continuar compra”.</li>
                                <li>
                                    Iniciar sesión en tu usuario de compra en el
                                    cual debes: Completar la información de
                                    contacto, la dirección de envío y la
                                    dirección de facturación. De no contar con
                                    usuario crear uno.
                                </li>
                                <li>
                                    Seleccionar el destino de envío y también el
                                    método de pago.
                                </li>
                                <li>
                                    Dependiendo del método de pago seleccionado
                                    el sitio te dirigirá a la pantalla
                                    respectiva:
                                </li>
                            </ol>
                            <div class="text-body2 q-mb-md">
                                Al momento de realizar el pago vía transferencia
                                la orden estará en el estado “PENDIENTE” hasta
                                que se confirme el pago donde se hará el cambio
                                a “COMPLETADO”. Para cualquier duda, usted podrá
                                contactarnos a través de nuestro correo
                                <a href="mailto:brewthers3@gmail.com"
                                    >brewthers3@gmail.com</a
                                >
                            </div>
                            <div class="text-body2 q-mb-md">
                                La opción de crédito será habilitada una vez el
                                Comercio haya realizado por tres meses seguidos
                                compras a través de este sitio web. Adicional,
                                los créditos (para quienes apliquen) sera para
                                compras por un monto superior a los $200
                                dólares.
                            </div>
                            <div class="text-body2">
                                Las condiciones de crédito que existieran
                                previamente entre negocio (comercio) y casa
                                cervecera se mantendrán vigentes a través de
                                esta plataforma digital siempre y cuando así lo
                                indique la casa cervecera.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                12- PROPIEDAD INTELECTUAL
                            </div>
                            <div class="text-body2">
                                Usted reconoce y consiente que todo Derecho de
                                autor, marca registrada, logotipos, nombres de
                                productos, diseños, textos, videos, títulos
                                utilizados en el sitio web y demás derechos de
                                propiedad intelectual sobre los materiales o
                                contenidos que se aportan como parte del sitio
                                web son propiedad de BREWTHERS o a quienes nos
                                otorgaron licencia para su uso. Queda prohibida
                                la utilización para otros fines que afecten los
                                Derechos de Propiedad Intelectual del TITULAR.
                                Sólo se permitirá efectuar una copia e imprimir
                                LA DOCUMENTACIÓN RELACIONADA A LA COMPRA DEL
                                PRODUCTO. Cualquier otra utilidad deberá ser
                                autorizada por el Titular en forma escrita. El
                                incumplimiento de lo anterior constituye una
                                infracción a los Derechos de Propiedad
                                Intelectual del Titular.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                13- COMUNICACIONES POR ESCRITO
                            </div>
                            <div class="text-body2">
                                La normativa aplicable exige que gran parte de
                                la información o comunicaciones que le enviemos
                                sean por escrito. Al usar este sitio web, usted
                                acepta que la mayor parte de las comunicaciones
                                con nosotros sean de forma electrónica. Nos
                                pondremos en contacto con usted por medio de su
                                correo electrónico o le facilitaremos
                                información colocando avisos en el sitio web. A
                                los efectos contractuales, usted está consciente
                                en hacer uso de este medio electrónico de
                                comunicación, y reconoce que todo contrato,
                                notificación, información y demás comunicaciones
                                que le enviemos de forma electrónica cumplen con
                                los requisitos legales. Esta condición no
                                afectará a sus derechos reconocidos por ley.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                14- NOTIFICACIONES
                            </div>
                            <div class="text-body2">
                                Las notificaciones que usted nos envíe deberán
                                hacerse preferiblemente a través de nuestra
                                sección de “Contáctanos”. Con arreglo a lo
                                dispuesto en la cláusula anterior y salvo que se
                                estipule lo contrario, le podremos enviar
                                comunicaciones al correo electrónico o a la
                                dirección facilitada por usted al momento de
                                realizar crear su usuario.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                15- CESIÓN DE DERECHOS Y OBLIGACIONES
                            </div>
                            <div class="text-body2">
                                El acuerdo entre usted y nosotros es vinculante
                                para ambas partes, así como para nuestros
                                respectivos sucesores, cesionarios y
                                causahabientes. En consecuencia, no podrá
                                transmitir, ceder, gravar o de cualquier otro
                                modo transferir un contrato o alguno de los
                                derechos u obligaciones derivados del mismo a su
                                favor o para usted, sin haber obtenido nuestro
                                consentimiento previo por escrito.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                16- RENUNCIA
                            </div>
                            <div class="text-body2">
                                Si durante la vigencia del acuerdo, dejásemos de
                                insistir en el cumplimiento estricto de alguna
                                de las obligaciones asumidas en virtud del mismo
                                o de alguno de los presentes términos y
                                condiciones, o si dejásemos de ejercer alguno de
                                los derechos o recursos que estuviésemos
                                facultados a interponer, tal hecho no
                                constituirá una renuncia a dichos derechos o
                                recursos ni le exonerará a usted de cumplir con
                                tales obligaciones. Ninguna renuncia surtirá
                                efectos, a no ser que se estipule de forma
                                expresa, y se le comunique a la otra parte de
                                conformidad con lo dispuesto en el apartado de
                                “Notificaciones”.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                17- INTEGRIDAD DEL CONTRATO
                            </div>
                            <div class="text-body2">
                                Los presentes términos y condiciones, así como
                                todo documento a que se haga referencia expresa
                                en los mismos, constituyen el acuerdo íntegro
                                existente entre usted y nosotros en relación con
                                el objeto del contrato y sustituyen a cualquier
                                otro pacto, acuerdo o promesa anterior convenida
                                entre usted y nosotros verbalmente o por
                                escrito.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                18- MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES
                            </div>
                            <div class="text-body2">
                                BREWTHERS se reserva el derecho de revisar y
                                modificar los presentes términos y condiciones
                                en cualquier momento, por lo que usted estará
                                sujeto a las políticas y condiciones vigentes en
                                el momento en que efectúe cada pedido, salvo que
                                por ley o decisión de organismos gubernamentales
                                debamos hacer cambios en dichas políticas,
                                términos y condiciones o declaración de
                                privacidad, en cuyo caso, los posibles cambios
                                afectarán también a los pedidos que usted
                                hubiese hecho previamente.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                19- LEGISLACIÓN APLICABLE Y JURISDICCIÓN
                            </div>
                            <div class="text-body2">
                                Los contratos de compra de productos a través de
                                nuestro sitio web se regirán por la legislación
                                de la República de Panamá.
                            </div>
                            <div class="text-body2">
                                Cualquier controversia derivada del presente
                                acuerdo, su existencia, validez, interpretación,
                                alcance o cumplimiento, será sometida a las
                                leyes panameñas aplicables, y a los tribunales
                                competentes de la ciudad de Panamá y los
                                procedimientos se llevarán a cabo en idioma
                                castellano.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                20- COMPRAS QUE INCLUYAN BEBIDAS ALCOHÓLICAS
                            </div>
                            <div class="text-body2">
                                Antes de empezar a utilizar el Servicio, usted
                                debe registrarse, obtener una cuenta y
                                asegurarse que toda orden que incluya la compra
                                de bebidas alcohólicas sólo podrá ser hecha por
                                consumidores mayores de 18 años de edad y por
                                locales comerciales con un RUC establecido en la
                                Ciudad de Panamá para la sección “Moving Beer”.
                                Queda establecido que está prohibida la venta o
                                suministro de bebidas alcohólicas a menores de
                                dieciocho (18) años de edad (Ley 55 de 1973).
                                Cuando se le vaya a entregar el pedido a la
                                persona, nuestro personal exigirá la
                                identificación de la persona que emitió la orden
                                de compra sea la cédula o pasaporte vigente para
                                la verificación de la edad. De no darse así,
                                nuestro equipo no podrá hacer la entrega de la
                                compra.
                            </div>
                            <div class="text-body2">
                                Al mismo tiempo la Plataforma, restringirá la
                                comercialización de bebidas alcohólicas en días
                                establecidos y aprobados bajo resoluciones
                                emitidas por el Gobierno de la República de
                                Panamá, como Ley Seca.
                            </div>
                            <div class="text-h5 q-mb-sm q-mt-lg">
                                21- CONTACTO
                            </div>
                            <div class="text-body2">Dirección:</div>
                            <div class="text-body2">
                                Teléfonos: +507 6480 9969/‭ +507 6216 6095‬ /
                                +507 6438 0357
                            </div>
                            <div class="text-body2">
                                Email:
                                <a href="mailto:brewthers3@gmail.com"
                                    >brewthers3@gmail.com</a
                                >
                            </div>
                            <div class="text-body2 q-mb-xl">
                                Entregas: Lunes a Viernes: 9:00 am - 6:00 pm
                            </div>
                        </div>
                    </div>
                    <div class="col"></div>
                </div>
            </q-page>
        </q-page-container>
        <brewthers-footer />
    </q-layout>
</template>

<script>
import GenericNavbar from '@/components/general/GenericNavbar'
import Footer from '@/components/general/Footer'

export default {
    components: {
        'generic-navbar': GenericNavbar,
        'brewthers-footer': Footer,
    },
    mounted() {
        window.scrollTo(0, 0)
    },
}
</script>

<style>
.terms-copy a {
    color: #24a2c2 !important;
}
</style>