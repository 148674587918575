var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-md"},[_c('loading-alert',{attrs:{"display":_vm.displayLoading}}),_c('brewthers-alert',{attrs:{"display":_vm.displayAlert,"title":_vm.alertTitle,"message":_vm.alertMessage,"type":_vm.alertType},on:{"accept":function($event){_vm.displayAlert = false}}}),(Object.keys(_vm.data).length !== 0)?_c('div',[_c('div',{staticClass:"text-h5 q-mb-md text-white"},[_vm._v("Detalles de articulo")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-xs-12 q-pa-md"},[_c('div',{staticClass:"row q-mb-md"},[_c('q-form',{staticClass:"full-width",on:{"submit":_vm.handleData}},[_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"Nombre","type":"text","disable":!_vm.editInformation,"rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"Estilo","disable":!_vm.editInformation},model:{value:(_vm.data.style),callback:function ($$v) {_vm.$set(_vm.data, "style", $$v)},expression:"data.style"}}),_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"Presentacion","value":_vm.data.type,"readonly":""}}),_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"Casa","value":_vm.returnBreweryName(_vm.data.brewery),"readonly":""}}),_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"ABV","disable":!_vm.editInformation,"rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.data.abv),callback:function ($$v) {_vm.$set(_vm.data, "abv", _vm._n($$v))},expression:"data.abv"}}),_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"IBU","disable":!_vm.editInformation,"rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.data.ibu),callback:function ($$v) {_vm.$set(_vm.data, "ibu", _vm._n($$v))},expression:"data.ibu"}}),_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"Descripción","type":"textarea","disable":!_vm.editInformation},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),_c('q-file',{staticClass:"q-mb-md",attrs:{"filled":"","dark":"","label":"Foto","disable":!_vm.editInformation},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"fas fa-paperclip"})]},proxy:true}],null,false,2479631777),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('q-input',{staticClass:"q-mb-md full-width",attrs:{"filled":"","dark":"","label":"Precio","disable":!_vm.editInformation,"rules":[
                                function (val) { return !!val || 'El campo es obligatorio'; } ]},model:{value:(_vm.data.price),callback:function ($$v) {_vm.$set(_vm.data, "price", _vm._n($$v))},expression:"data.price"}}),(_vm.user.role != 'brewery')?_c('q-btn',{attrs:{"type":"submit","color":"info","label":_vm.editInformation ? 'Guardar' : 'Editar'}}):_vm._e()],1)],1)]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-xs-12 q-pa-md"},[_c('q-img',{staticClass:"q-mb-md",attrs:{"src":_vm.data.photoLocation}}),(_vm.user.role === 'brewery')?_c('div',{staticClass:"text-h6 q-mb-md text-white"},[_vm._v(" Cantidad en inventario: "+_vm._s(_vm.data.inventory)+" ")]):_vm._e()],1),(_vm.user.role != 'brewery')?_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-xs-12 q-pa-md"},[_c('div',{staticClass:"text-h6 q-mb-md text-white"},[_vm._v(" Cantidad en inventario: "+_vm._s(_vm.data.inventory)+" ")]),_c('q-input',{staticClass:"q-mb-md",attrs:{"dark":"","filled":"","label":"Agregar a inventario","type":"number","rules":[
                        function (val) { return !!val || 'El campo es obligatorio'; },
                        function (val) { return val > 0 || 'El valor debe ser mayor a 0'; } ]},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('q-btn',{attrs:{"round":"","color":"secondary"},on:{"click":function($event){_vm.addToInventory(parseInt(_vm.addInventory))}}},[_c('i',{staticClass:"fas fa-plus"})])]},proxy:true}],null,false,1825578366),model:{value:(_vm.addInventory),callback:function ($$v) {_vm.addInventory=$$v},expression:"addInventory"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"dark":"","filled":"","label":"Disminuir de inventario","type":"number","rules":[
                        function (val) { return !!val || 'El campo es obligatorio'; },
                        function (val) { return val > 0 || 'El valor debe ser mayor a 0'; } ]},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('q-btn',{attrs:{"round":"","color":"red-7"},on:{"click":function($event){_vm.subtractToInventory(
                                    parseInt(_vm.substractInventory)
                                )}}},[_c('i',{staticClass:"fas fa-minus"})])]},proxy:true}],null,false,3488609352),model:{value:(_vm.substractInventory),callback:function ($$v) {_vm.substractInventory=$$v},expression:"substractInventory"}})],1):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }